@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Roboto', sans-serif;
}

.dark-card-element .StripeElement {
    color: white !important;
  }
  
  .dark-card-element .StripeElement--webkit-autofill {
    -webkit-text-fill-color: white !important;
  }

  .markdown-content h1 {
    font-size: 2em;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  
  .markdown-content h2 {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  
  .markdown-content h3 {
    font-size: 1.17em;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  
  .markdown-content ul, .markdown-content ol {
    padding-left: 2em;
    margin-bottom: 1em;
  }
  
  .markdown-content ul {
    list-style-type: disc;
  }
  
  .markdown-content ol {
    list-style-type: decimal;
  }
  
  .markdown-content li {
    margin-bottom: 0.5em;
  }
  
  .markdown-content p {
    margin-bottom: 1em;
  }
  
  .markdown-content blockquote {
    border-left: 4px solid #ccc;
    padding-left: 1em;
    margin-left: 0;
    font-style: italic;
  }